import { css } from "@emotion/react";
import { white, mediumGreen, lightGreen, veryDarkGray } from "./colors";
import {
    fontSize14,
    fontSize16,
    fontSize18,
    fontSize20,
    fontSize36,
    fontSize42,
    fontSize28,
} from "./fonts";
import {
    mobileMaxWith,
    maxContentWidth,
    halfMaxContentWidth,
    smallMaxWith,
    mediumMaxWith,
    largeMaxWith,
} from "./constants";

export const mediaMobile = `@media (max-width: ${mobileMaxWith}px)`;
export const mediaLargerThanMobile = `@media (min-width: ${
    mobileMaxWith + 0.01
}px)`;
export const mediaSmall = `@media (max-width: ${smallMaxWith}px)`;
export const mediaMedium = `@media (max-width: ${mediumMaxWith}px)`;
export const mediaLarge = `@media (max-width: ${largeMaxWith}px)`;

export const centeredMaxWidthSpacing = `calc(50% - ${halfMaxContentWidth}px)`;
export const mediaMaxPageWidth = `@media (max-width: ${
    maxContentWidth + 64
}px)`;

export const centeredBlock = css`
    padding-left: ${centeredMaxWidthSpacing};
    padding-right: ${centeredMaxWidthSpacing};

    ${mediaMaxPageWidth} {
        padding-left: 32px;
        padding-right: 32px;
    }
    ${mediaSmall} {
        padding-left: 24px;
        padding-right: 24px;
    }
    ${mediaMobile} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const mediumGreenHover = css`
    color: ${mediumGreen};

    ${mediaLargerThanMobile} {
        :hover {
            color: #225600;
        }
        :active {
            color: #1f4f00;
        }
    }
`;

export const whiteHover = css`
    color: ${white};
    ${mediaLargerThanMobile} {
        :hover {
            color: ${lightGreen};
        }
        :active {
            color: ${mediumGreen};
        }
    }
`;

export const legalTextStyle = css`
    p {
        ${fontSize14};
        color: ${veryDarkGray};
        margin-bottom: 8px;
    }

    ul {
        ${fontSize14};
        line-height: 1.5;
        color: ${veryDarkGray};
        padding-left: 24px;
        margin-bottom: 8px;
    }

    strong {
        font-weight: 700;
    }

    h1 {
        ${fontSize36};
        font-weight: 700;
        color: ${mediumGreen};
        margin: 50px 0 28px;

        ${mediaMobile} {
            ${fontSize28};
        }
    }
    h2 {
        ${fontSize20};
        font-weight: 700;
        color: ${mediumGreen};
        margin: 36px 0 20px;
    }
    h3 {
        ${fontSize16};
        font-weight: 700;
        color: ${mediumGreen};
        margin: 28px 0 10px;
    }

    a {
        ${fontSize14};
        ${mediumGreenHover};
    }
`;

export const fontSizeHeader = css`
    ${fontSize42};
    ${mediaMedium} {
        ${fontSize36};
    }
    ${mediaMobile} {
        ${fontSize28};
    }
`;

export const fontSizeText = css`
    ${fontSize18};
    ${mediaMobile} {
        ${fontSize16};
    }
`;

export const boxShadow = css`
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.5);
`;

export const ctaButtonStyle = css`
    padding: 8px 16px;
    border: 1px solid ${white};
    border-radius: 1px;
    cursor: pointer;
    color: ${white};
    background-color: transparent;
    display: block;
    text-decoration: none;

    ${fontSize20};

    ${mediaSmall} {
        ${fontSize18};
    }

    :hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;
