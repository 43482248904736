import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css, keyframes } from "@emotion/react";
import Img from "gatsby-image";
import { fontSize36, fontSize44, fontSize28 } from "../style/fonts";
import {
    centeredMaxWidthSpacing,
    mediaMobile,
    mediaMaxPageWidth,
    mediaSmall,
    mediaLarge,
} from "../style/shared";
import LogoWithOutline from "./BigLogo";
import { halfMaxContentWidth } from "../style/constants";

const headerStyle = css`
    position: relative;
    height: 32vw;
    max-height: 490px;
    min-height: 390px;
    box-sizing: border-box;
    overflow: hidden;

    ${mediaMobile} {
        height: 330px;
        min-height: 330px;
    }
`;

const backgoundWrapperStyle = css`
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    filter: brightness(0.9);
`;

const logoStyle = css`
    position: absolute;
    top: 50px;
    left: calc(50% - ${halfMaxContentWidth}px - 140px);

    ${mediaLarge} {
        left: 16px;
        top: 16px;
    }

    ${mediaMobile} {
        left: 8px;
    }
`;

const centralWrapperStyle = css`
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(
        rgba(0, 0, 0, 0.55) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0) 75%,
        rgba(0, 0, 0, 0.35) 100%
    );

    ${mediaSmall} {
        background: linear-gradient(
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 0) 75%,
            rgba(0, 0, 0, 0.35) 100%
        );
    }
`;

const fadeIn = keyframes`
  from, 20% { opacity: 0; }
  to   { opacity: 1; }
`;
const sloganStyle = css`
  position: absolute;
  animation: ${fadeIn} 5s;
  top: 40%;
  right: ${centeredMaxWidthSpacing};
  max-width: 650px; // make sure there is a line break
  text-align: right;
  ${fontSize44};
  color: white;
  text-shadow: 0 0 350px #f7f7b7de, 0 0 1px black, 0 0 1px black;

  ${mediaMaxPageWidth} {
    right: 24px;
    ${fontSize36};
  }

  ${mediaMobile} {
    right: 16px;
    left: 16px;
    top: 45%;
    text-align: center;
    ${fontSize28};
  }
}`;

export default () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "yoal-desurmont-523820-unsplash.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <header css={headerStyle}>
            <Img
                css={backgoundWrapperStyle}
                fluid={data.file.childImageSharp.fluid}
                imgStyle={{
                    objectFit: "cover",
                    objectPosition: "50% 50%",
                    fontFamily: `"object-fit: cover; object-position: 50% 50%"`,
                }}
                alt="Dunkler, undurchsichtiger Dschungel"
            />
            <div css={centralWrapperStyle}>
                <LogoWithOutline style={logoStyle} />
                <p css={sloganStyle}>
                    Wir helfen Ihnen durch den Steuerdschungel.
                </p>
            </div>
        </header>
    );
};
