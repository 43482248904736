import React from "react";
import { white } from "../style/colors";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { defaultFont } from "../style/fonts";

const boxStyle = css`
    display: flex;
    cursor: pointer;
    text-decoration: none;
`;

const logoStyle = css`
    height: 80px;
    width: 85px;
`;

const nameStyle = css`
    ${defaultFont};
    color: ${white};
    padding-top: 15px;
    margin-left: -12px;
    font-size: 30px;
    letter-spacing: 0.01em;
    line-height: 0.88;
    font-weight: 700;
`;

export default ({ style }) => (
    <Link css={[boxStyle, style]} to="/">
        <svg css={logoStyle} xmlns="http://www.w3.org/2000/svg">
            <use xlinkHref="#logo" />
        </svg>
        <p css={nameStyle}>
            Oliver
            <br />
            Schmitt
        </p>
    </Link>
);
