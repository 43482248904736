import React from "react";
import CookiePopup from "./CookiePopup";

const GA_ID = "UA-113055628-2";
const DISABLE_STR = "ga-disable-" + GA_ID;
const ANAYTICS_ENABLED_LOCAL_STORAGE_KEY = "ga-enabled";

export function gaOptout() {
    document.cookie =
        DISABLE_STR + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
    window[DISABLE_STR] = true;
}

function addTrackingScript(firstTime) {
    const script = document.createElement("script");
    script.innerHTML = `                
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ 
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), 
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) 
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); 

    ga('create', '${GA_ID}', 'auto'); 
    ga('set', 'anonymizeIp', true); 
    ga('send', 'pageview');
    ${firstTime ? "ga('send', 'event', 'cookie', 'consent');" : ""}
  `;

    if (process.env.NODE_ENV === "production") {
        document.body.appendChild(script);
    } else {
        console.debug("[Analytics] would append script", script);
    }
}

export default class Analytics extends React.Component {
    state = { askForConsent: false };

    componentDidMount() {
        const optedOpt = document.cookie.indexOf(DISABLE_STR + "=true") > -1;
        const givenConsent = this.hasGivenConsentBefore();
        this.setState({ askForConsent: !optedOpt && !givenConsent });

        if (givenConsent) {
            addTrackingScript();
        }
    }

    hasGivenConsentBefore() {
        try {
            return !!localStorage.getItem(ANAYTICS_ENABLED_LOCAL_STORAGE_KEY);
        } catch (e) {
            return false;
        }
    }

    enableTracking = () => {
        this.setState({ askForConsent: false });

        addTrackingScript(true);
        try {
            localStorage.setItem(ANAYTICS_ENABLED_LOCAL_STORAGE_KEY, "true");
        } catch (e) {}
    };

    disableTracking = () => {
        gaOptout();
        try {
            localStorage.clear();
        } catch (e) {}

        this.setState({ askForConsent: false });
    };

    render() {
        if (!this.state.askForConsent) {
            return null;
        }
        return (
            <CookiePopup
                onOkClick={this.enableTracking}
                onDeclineClick={this.disableTracking}
            />
        );
    }
}
