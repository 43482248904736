import React from "react";
import { white } from "../style/colors";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { defaultFont } from "../style/fonts";
import { mediaSmall, mediaLarge } from "../style/shared";

const boxStyle = css`
    display: flex;
    cursor: pointer;
    text-decoration: none;
`;

const logoStyle = css`
    filter: drop-shadow(0 0 16px, black);
    height: 118px;
    width: 125px;

    ${mediaLarge} {
        height: 110px;
        width: 116px;
    }

    ${mediaSmall} {
        height: 76px;
        width: 79px;
    }
`;

const textBoxStyle = (type) => css`
    ${defaultFont};
    color: ${white};
    padding-top: 22px;
    margin-left: -11px;

    ${mediaLarge} {
        padding-top: 20px;
    }

    ${mediaSmall} {
        margin-left: -8px;
        padding-top: 15px;
    }
`;
const nameStyle = css`
    font-size: 47px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.005em;

    ${mediaLarge} {
        letter-spacing: 0;
        font-size: 42px;
    }

    ${mediaSmall} {
        letter-spacing: 0;
        font-size: 31px;
        line-height: 1;
    }
`;

const companyStyle = css`
    font-size: 21px;

    ${mediaLarge} {
        font-size: 19px;
    }

    ${mediaSmall} {
        font-size: 14px;
    }
`;

export default ({ style, type }) => (
    <Link css={[boxStyle, style]} to="/">
        <svg css={logoStyle} xmlns="http://www.w3.org/2000/svg">
            <use xlinkHref="#logo" />
        </svg>
        <div css={textBoxStyle}>
            <p css={nameStyle}>Oliver Schmitt</p>
            <p css={companyStyle}>Steuerberatungsgesellschaft mbH</p>
        </div>
    </Link>
);
