// maybe needed for newsletter later
// import "whatwg-fetch";

import React from "react";
import Layout from "./src/layout";

// https://github.com/gatsbyjs/gatsby/issues/4021
export const onClientEntry = () => {
    // Object-fit/Object-position polyfill for gatsby-image (IE)
    const testImg = document.createElement("img");
    if (typeof testImg.style.objectFit === "undefined" || typeof testImg.style.objectPosition === "undefined") {
        import("object-fit-images").then(objectFitImages => objectFitImages.default());
    }
};

export const onRouteUpdate = ({ location }) => {
    const currentUrl = location ? location.pathname + location.search + location.hash : undefined;
    if (process.env.NODE_ENV === "production") {
        if (typeof ga === "function") {
            window.ga("set", "page", currentUrl);
            window.ga("send", "pageview");
        }
    } else {
        console.debug("[Analytics] would send pageview for: ", currentUrl);
    }
};

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
};
