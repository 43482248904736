export const lightGreen = "#499026";
export const mediumGreen = "#004f20";
export const darkGreen = "#122018";

export const lightGray = "#e5e3e0";
export const mediumGray = "#acb2b5";
export const darkGray = "#8C8C8C";
export const veryDarkGray = "#222";

export const white = "#FFF";
export const black = "#000";
