import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import SmallLogo from "./SmallLogo";
import { mediumGray, black } from "../style/colors";
import { fontSize14, fontSize16 } from "../style/fonts";
import {
    centeredMaxWidthSpacing,
    mediaSmall,
    mediaMobile,
    mediaMedium,
    mediaMaxPageWidth,
    whiteHover,
    centeredBlock,
} from "../style/shared";

const footerStyle = css`
    margin-top: -1px; // somehow IE11 sometimes renders a gap between the footer and last banner
    background-color: ${black};
    display: flex;
    ${centeredBlock};
    padding-top: 24px;
    padding-bottom: 44px;
    align-items: flex-start;
    position: relative;
    justify-content: space-between;
    overflow: hidden;

    ${mediaMedium} {
        display: block;
        padding-top: 32px;
        padding-bottom: 10px;
    }

    ${mediaMobile} {
        padding-bottom: 58px;
    }
`;

const navStyle = css`
    padding: 13px 0;

    ${mediaMedium} {
        padding: 0;
    }
    ${mediaMobile} {
        margin-left: 20px;
    }
`;

const logoColumn = css`
    order: -1;
`;

const logoStyle = css`
    margin-left: -20px;

    ${mediaMedium} {
        margin-top: 32px;
    }

    ${mediaMobile} {
        margin-top: 48px;
        margin-left: 0px;
    }
`;

const linkStyle = css`
    padding: 4px 0 4px 46px;
    text-decoration: none;

    ${fontSize16};
    ${whiteHover};

    ${mediaMedium} {
        padding-bottom: 8px;
        display: block;
        padding-left: 0px;
    }

    ${mediaMobile} {
        padding-bottom: 23px;
        flex: 1 1 auto;
    }
`;

const copyrightStyle = css`
    position: absolute;
    right: ${centeredMaxWidthSpacing};
    bottom: 24px;
    color: ${mediumGray};
    ${fontSize14};

    ${mediaMaxPageWidth} {
        right: 32px;
    }
    ${mediaSmall} {
        right: 24px;
    }
    ${mediaMobile} {
        left: 36px;
        right: auto;
    }
`;

export default () => (
    <footer css={footerStyle}>
        <nav css={navStyle}>
            <Link css={linkStyle} to="/jobs">
                Jobs
            </Link>
            <Link css={linkStyle} to="/#kontakt">
                Kontakt
            </Link>
            <Link css={linkStyle} to="/impressum">
                Impressum
            </Link>
            <Link css={linkStyle} to="/datenschutz">
                Datenschutzerklärung
            </Link>
        </nav>
        <div css={logoColumn}>
            <SmallLogo style={logoStyle} />
        </div>
        <div css={copyrightStyle}>
            Copyright &copy; {new Date().getFullYear()} Oliver Schmitt
        </div>
    </footer>
);
