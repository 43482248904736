import React from "react";
import { css } from "@emotion/react";
import { mediumGreen, white } from "../style/colors";
import { fontSize14, fontSize16 } from "../style/fonts";
import { mediaSmall, boxShadow } from "../style/shared";
import { Link } from "gatsby";

const bannerStyle = css`
    background-color: ${mediumGreen};
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1001;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 18px 24px;
    ${boxShadow};

    ${mediaSmall} {
        display: block;
    }
`;

const buttonStyle = css`
    border: 1px solid ${white};
    color: ${white};
    background-color: transparent;
    border-radius: 0;
    flex: none;

    ${fontSize16};
    margin-left: 16px;
    padding: 4px 11px;
    cursor: pointer;
    white-space: nowrap;

    :hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    ${mediaSmall} {
        margin: 16px 16px 0 0;
    }
`;

const textStyle = css`
    color: ${white};
    ${fontSize14};
    margin-right: auto;
`;

const linkStyle = css`
    ${textStyle};
    text-decoration: underline;
    cursor: pointer;
`;

export default ({ onOkClick, onDeclineClick }) => (
    <div css={bannerStyle}>
        <p css={textStyle}>
            Diese Website verwendet Cookies – nähere Informationen dazu und zu
            Ihren Rechten als Benutzer finden Sie in unserer{" "}
            <Link css={linkStyle} to="/datenschutz">
                Datenschutzerklärung
            </Link>
            . Klicken Sie auf „Ich stimme zu“, um Cookies zu akzeptieren.
        </p>
        <button css={buttonStyle} onClick={onDeclineClick}>
            Ich lehne ab
        </button>
        <button css={buttonStyle} onClick={onOkClick}>
            Ich stimme zu
        </button>
    </div>
);
