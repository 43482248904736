import { css } from "@emotion/react";

export const defaultFont = css`
    font-family: arial, san-serif;
`;

export const fontSize11 = css`
    ${defaultFont}
    font-weight: 400;
    font-size: 11px;
    line-height: 1.2;
`;

export const fontSize14 = css`
    ${defaultFont}
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
`;

export const fontSize16 = css`
    ${defaultFont}
    font-weight: 400;
    font-size: 16px;
    line-height: 1.35;
`;

export const fontSize18 = css`
    ${defaultFont}
    font-weight: 400;
    font-size: 18px;
    line-height: 1.35;
`;

export const fontSize20 = css`
    ${defaultFont}
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
`;

export const fontSize24 = css`
    ${defaultFont}
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
`;

export const fontSize28 = css`
    ${defaultFont}
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
`;

export const fontSize36 = css`
    ${defaultFont}
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
`;

export const fontSize42 = css`
    ${defaultFont}
    font-weight: 700;
    font-size: 42px;
    line-height: 1.2;
`;

export const fontSize44 = css`
    ${defaultFont}
    font-weight: 700;
    font-size: 44px;
    line-height: 1.2;
`;
